// NODE MODULES
import { Fragment } from "react";
import ContactInfo from "./ContactInfo";

// COMPONENTS

// CONTENT
const Content = () => (
    <Fragment>
        <ContactInfo />
    </Fragment>
);

export default Content;
