// NODE MODULES
import { Modal } from "react-bootstrap";
import Zoom from "react-medium-image-zoom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

// CONTENT
const ImageSlider = ({
    show,
    handleClose,
    images,
}: {
    show: boolean;
    handleClose: () => void;
    images?: string[];
}) => {
    if (images && images.length > 0)
        return (
            <Modal size="lg" show={show} onHide={handleClose}>
                <Swiper
                    className="mySwiper"
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={10}
                    slidesPerView={1}
                    loop={true}
                    navigation
                    pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                >
                    {images.map((image) => (
                        <SwiperSlide key={image}>
                            <Zoom>
                                <img
                                    src={image}
                                    className="d-block custum-image-carousel"
                                    alt="not-found"
                                />
                            </Zoom>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Modal>
        );
    return <></>;
};

export default ImageSlider;
