// NODE MODULES
import { Link } from "react-router-dom";

// INTERFACES
interface IBreadcrumb {
    pagename: string;
}
// CONTENT
const Breadcrumb = ({
    breadcrumb: { pagename },
}: {
    breadcrumb: IBreadcrumb;
}) => (
    <div className="subheader bg-cover bg-center dark-overlay subheader-custom">
        <div className="container">
            <div className="subheader-inner">
                <h1 className="text-white">{pagename}</h1>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/">
                                <i className="fas fa-home" />
                            </Link>
                        </li>
                        <li
                            className="breadcrumb-item active"
                            aria-current="page"
                        >
                            {pagename}
                        </li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
);

export default Breadcrumb;
