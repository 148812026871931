// NODE MODULES
import { Fragment } from "react";
import { Link } from "react-router-dom";

// IMPORTS
import logo from "assets/img/ressources/js-inline.png";

// CONTENT
const Menu = () => (
    <Fragment>
        {/* Logo */}
        <Link className="navbar-brand" to="/">
            <img src={logo} alt="not-found" />
        </Link>
        {/* Menu */}
        <ul className="navbar-nav">
            <li className="menu-item">
                <Link to="/">Accueil</Link>
            </li>
            <li className="menu-item">
                <Link to="/a-propos">A Propos</Link>
            </li>
            <li className="menu-item">
                <Link to="/contact">Contact</Link>
            </li>
        </ul>
    </Fragment>
);

export default Menu;
