// NODE MODULES
import Zoom from "react-medium-image-zoom";

// IMPORTS
import imgProject1 from "assets/img/ressources/flyer1.jpg";
import imgProject2 from "assets/img/ressources/flyer2.jpg";
import { Link } from "react-router-dom";

// CONTENT
const Project = () => (
    <div className="section section-padding">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 acr-single-img-wrapper mb-lg-30">
                    <div className="section-title-wrap section-header acr-dots-wrapper">
                        <h2 className="title">
                            Le projet :
                            <span className="custom-project-color">
                                {" "}
                                Résidence Lilya
                            </span>
                        </h2>
                        <div className="acr-dots" />
                    </div>
                    <Zoom>
                        <img src={imgProject1} alt="not-found" />
                    </Zoom>
                </div>
                <div className="col-lg-6 acr-single-img-wrapper">
                    <div className="section-title-wrap">
                        <Zoom>
                            <img src={imgProject2} alt="not-found" />
                        </Zoom>
                        <p className="subtitle">
                            Découvrez ces appartements nichés au centre du
                            quartier des Hôpitaux, avec un emplacement paisible
                            et tranquille, à proximité de commerces, écoles et
                            de transports publics. Profitez de la sérénité de
                            cette localisation tout en ayant tout ce dont vous
                            avez besoin à portée de main.
                        </p>
                        <p>
                            Nos appartements luxueux sont conçus pour votre
                            confort ultime. Chaque espace intérieur comprend un
                            balcon ou une terrasse privés, une cuisine
                            entièrement équipée, un salon spacieux, et une à
                            deux salles de bain élégantes. Profitez d'un
                            intérieur moderne et fonctionnel où chaque détail
                            est pensé pour améliorer votre qualité de vie.
                        </p>
                    </div>
                </div>
                <div className="col-lg-12 acr-single-img-wrapper mt-5">
                    <div className="section-title-wrap section-header acr-dots-wrapper">
                        <h2 className="title">
                            Localisation :
                            <span className="custom-project-color">
                                {" "}
                                Résidence Lilya
                            </span>
                        </h2>
                        <div className="acr-dots" />
                    </div>
                    <div className="col-lg-4 d-block d-md-none">
                        <div className="acr-infographic-item d-flex custom-icon-color">
                            <i className={"flaticon-location"} />
                            <div className="acr-infographic-item-body">
                                <h5>
                                    Nous somme disponible tous les jours de la
                                    semaine pour programmer une viste !
                                </h5>
                                <Link
                                    to="https://maps.app.goo.gl/HkFe11NqooptQxdL6"
                                    target="_blank"
                                    className="btn-custom secondary btn-sm"
                                >
                                    Google Maps
                                </Link>
                            </div>
                        </div>
                    </div>
                    <iframe
                        title="mapProjecty"
                        width="100%"
                        height="450"
                        // style="border:0"
                        loading="lazy"
                        allowFullScreen
                        referrerPolicy="no-referrer-when-downgrade"
                        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3324.3580198506597!2d-7.621668624304105!3d33.57005247334234!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDM0JzEyLjIiTiA3wrAzNycwOC43Ilc!5e0!3m2!1sfr!2sma!4v1697806599479!5m2!1sfr!2sma"
                    ></iframe>
                </div>
            </div>
        </div>
    </div>
);

export default Project;
