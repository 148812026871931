// NODE MODULES
import { Link } from "react-router-dom";

// IMPORTS
import config from "data/config.json";

// CONTENT
const Contact = () => (
    <div className="footer-top">
        <div className="container">
            <div className="row footer-btn-wrapper">
                <div className="col-lg-5 footer-widget">
                    <h4>Contactez-nous</h4>
                    <p>
                        Contactez-nous dès que possible si vous êtes intéressé,
                        afin de convenir d'une visite dans les délais les plus
                        courts !
                    </p>
                </div>
                <div className="col-lg-7 col-md-12 footer-widget">
                    <div className="footer-btn">
                        <Link
                            to="https://www.facebook.com/js.market.maroc/"
                            target="_blank"
                            className="btn-custom-2 grey"
                        >
                            <i className="flaticon-facebook" />
                            Facebook
                        </Link>
                        <Link
                            to="https://www.instagram.com/js.market.maroc/"
                            target="_blank"
                            className="btn-custom-2 grey"
                        >
                            <i className="flaticon-instagram" />
                            Instagram
                        </Link>
                        <Link
                            id="clickButtonWhatsapp1"
                            to={config.whatsappLink}
                            target="_blank"
                            className="btn-custom-2 grey"
                        >
                            <i className="fab fa-whatsapp" />
                            Whatsapp
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Contact;
