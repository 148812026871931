// NODE MODULES
import { Fragment } from "react";

// COMPONENTS
import Banner from "components/sections/home/Banner";
import WhyUs from "components/sections/home/WhyUs";
import Project from "components/sections/home/Project";
import ItemsList from "components/sections/home/ItemsList";

// CONTENT
const Content = () => (
    <Fragment>
        <Banner />
        <Project />
        <ItemsList />
        <WhyUs />
    </Fragment>
);

export default Content;
