// NODE MODULES
import { Link } from "react-router-dom";

// COMPONENTS
import Contact from "components/layouts/Contact";

// IMPORTS
import logo from "assets/img/ressources/js-block.png";
import config from "data/config.json";

// CONTENT
const Footer = () => (
    <footer className="acr-footer footer-2">
        {/* Footer Top Start */}
        <Contact />
        {/* Footer Top End */}
        {/* Footer Middle Start */}
        <div className="footer-middle">
            <div className="container">
                <div className="row">
                    <div className="col-lg-9 col-sm-12 footer-widget">
                        <div className="footer-logo">
                            <img src={logo} alt="not-found" />
                        </div>
                        <p>
                            Bienvenue chez {config.appName}, votre partenaire de
                            confiance pour toutes vos transactions immobilières.
                            Notre expertise et notre engagement sont à votre
                            service pour concrétiser vos projets immobiliers.
                            Découvrez notre vaste portefeuille de biens
                            immobiliers et faites le premier pas vers la maison
                            de vos rêves.
                        </p>
                    </div>
                    <div className="col-lg-2 offset-lg-1 col-sm-4 footer-widget">
                        <h5 className="widget-title">Menu</h5>
                        <ul>
                            <li>
                                <Link to="/">Accueil</Link>
                            </li>
                            <li>
                                <Link to="/a-propos">A propos</Link>
                            </li>
                            <li>
                                <Link to="/contact">Contactez-nous</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        {/* Footer Middle End */}
        {/* Footer Bottom Start */}
        <div className="footer-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <p className="m-0">
                            {config.appName}© Copyright |{" "}
                            {new Date().getFullYear()} -{" "}
                            <Link to="#">JS Corp</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        {/* Footer Bottom End */}
    </footer>
);

export default Footer;
