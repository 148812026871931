//

// CONTENT
const Banner = () => (
    <div className="banner banner-1 bg-cover bg-center banner-custom">
        <div className="container">
            <div className="banner-item">
                <div className="banner-inner">
                    <div className="banner-text">
                        <h1 className="title">
                            Trouvez l'appartement de Vos Rêves
                        </h1>
                        <p className="subtitle">
                            L'Agence Immobilière de Confiance pour Réaliser Vos
                            Projets
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Banner;
