// NODE MODULES
import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

// COMPONENTS
import Mobilemenu from "components/layouts/Mobilemenu";
import Menu from "components/layouts/Menu";

// IMPORTS
import config from "data/config.json";

// CONTENT
const Header = () => {
    const [navtoggle, setNavToggle] = useState(false);
    const [isTop, setIsTop] = useState(true);

    const navtoggleClass = () => {
        setNavToggle(!navtoggle);
    };

    useEffect(() => {
        const handleScroll = () => {
            setIsTop(window.scrollY > 100);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const stickyheader = isTop ? "sticky" : "";

    return (
        <Fragment>
            {/* Aside (Mobile Navigation) */}
            <aside
                className={classNames("main-aside", {
                    open: navtoggle,
                })}
            >
                <div className="aside-title">
                    <div className="aside-controls aside-trigger">
                        <h4>Menu</h4>
                        <div
                            className="close-btn close-dark"
                            onClick={navtoggleClass}
                        >
                            <span />
                            <span />
                        </div>
                    </div>
                </div>
                <Mobilemenu />
            </aside>
            <div
                className="aside-overlay aside-trigger"
                onClick={navtoggleClass}
            />
            {/* Header Start */}
            <header
                className={`main-header header-fw can-sticky header-1 ${stickyheader}`}
            >
                {/* Top Header Start */}
                <div className="top-header">
                    <div className="top-header-inner">
                        <ul className="social-media">
                            <li>
                                <Link
                                    to="https://www.facebook.com/js.market.maroc/"
                                    target="_blank"
                                >
                                    <i className="fab fa-facebook-f" />
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="https://www.instagram.com/js.market.maroc/"
                                    target="_blank"
                                >
                                    <i className="fab fa-instagram" />
                                </Link>
                            </li>
                            <li>
                                <Link to={config.whatsappLink} target="_blank">
                                    <i className="fab fa-whatsapp" />
                                </Link>
                            </li>
                        </ul>
                        <ul className="top-header-nav"></ul>
                    </div>
                </div>
                {/* Top Header End */}
                <nav className="navbar">
                    {/* Menu */}
                    <Menu />
                    <div className="header-controls">
                        <ul className="header-controls-inner me-4 me-lg-0">
                            <li>
                                <a
                                    href={config.phoneLink}
                                    className="btn-custom primary"
                                >
                                    Contactez-nous
                                    <i className="fas fa-phone" />
                                </a>
                            </li>
                        </ul>
                        {/* Toggler */}
                        <div
                            className="aside-toggler aside-trigger"
                            onClick={navtoggleClass}
                        >
                            <span />
                            <span />
                            <span />
                        </div>
                    </div>
                </nav>
            </header>
            {/* Header End */}
        </Fragment>
    );
};

export default Header;
