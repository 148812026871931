// IMPORTS
import config from "data/config.json";

// CONTENT
const WhyUs = () => {
    const items = [
        {
            id: 1,
            icon: "star",
            title: "Expertise Locale",
            text: "Nous connaissons le marché comme personne d'autre.",
        },
        {
            id: 2,
            icon: "apartment",
            title: "Transparence Totale",
            text: "Vous pouvez compter sur nous.",
        },
        {
            id: 3,
            icon: "sales-agent",
            title: "Service Personnalisé",
            text: "Vos besoins sont notre priorité.",
        },
    ];

    return (
        <div className="section infographics-3">
            <div className="container">
                <div className="section-title-wrap section-header">
                    <h5 className="custom-primary">{config.appName}</h5>
                    <h2 className="title">Pourquoi nous choisir ?</h2>
                </div>
                <div className="row">
                    {items.map((item, i) => (
                        <div key={i} className="col-lg-4">
                            <div className="acr-infographic-item">
                                <i className={"flaticon-" + item.icon + ""} />
                                <div className="acr-infographic-item-body">
                                    <h5>{item.title}</h5>
                                    <p>{item.text}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default WhyUs;
