// NODE MODULES
import { Link } from "react-router-dom";

// IMPORTS
import navigation from "data/navigation.json";
import config from "data/config.json";

// INTERFACES
interface INavigation {
    link: string;
    linkText: string;
    icon: string;
    child: boolean;
    submenu: INavigation[];
}

// CONTENT
const Mobilemenu = () => {
    const getNextSibling = (elem: any, selector: any) => {
        var sibling = elem.nextElementSibling;
        if (!selector) return sibling;
        while (sibling) {
            if (sibling.matches(selector)) return sibling;
            sibling = sibling.nextElementSibling;
        }
    };
    const triggerChild = (e: any) => {
        let subMenu: any = "";
        subMenu =
            getNextSibling(e.target, ".submenu") !== undefined
                ? getNextSibling(e.target, ".submenu")
                : null;
        if (subMenu !== null && subMenu !== undefined && subMenu !== "") {
            subMenu.classList = subMenu.classList.contains("d-block")
                ? "submenu"
                : "submenu d-block";
        }
    };

    return (
        <div className="aside-scroll">
            <ul>
                {/* Pages Start */}
                <li className="menu-section-title">Pages</li>
                {navigation.length > 0
                    ? navigation.map((item: INavigation, i: number) => (
                          <li
                              key={i}
                              className={`menu-item ${
                                  item.child ? "menu-item-has-children" : ""
                              } `}
                              onClick={triggerChild}
                          >
                              {item.child ? (
                                  <Link
                                      onClick={(e) => e.preventDefault()}
                                      to="/"
                                  >
                                      <i
                                          className={
                                              "flaticon-" + item.icon + ""
                                          }
                                      />
                                      {item.linkText}
                                  </Link>
                              ) : (
                                  <Link to={item.link}>
                                      <i
                                          className={
                                              "flaticon-" + item.icon + ""
                                          }
                                      />
                                      {item.linkText}
                                  </Link>
                              )}
                              {item.child ? (
                                  <ul className="submenu" role="menu">
                                      {item.submenu.map(
                                          (
                                              sub_item: INavigation,
                                              i: number
                                          ) => (
                                              <li
                                                  key={i}
                                                  className={`menu-item ${
                                                      sub_item.child
                                                          ? "menu-item-has-children"
                                                          : ""
                                                  } `}
                                              >
                                                  {sub_item.child ? (
                                                      <Link
                                                          onClick={(e) =>
                                                              e.preventDefault()
                                                          }
                                                          to="/"
                                                      >
                                                          {sub_item.linkText}
                                                      </Link>
                                                  ) : (
                                                      <Link to={sub_item.link}>
                                                          {sub_item.linkText}
                                                      </Link>
                                                  )}
                                                  {sub_item.submenu ? (
                                                      <ul className="submenu">
                                                          {sub_item.submenu.map(
                                                              (
                                                                  third_item: INavigation,
                                                                  i: number
                                                              ) => (
                                                                  <li
                                                                      className="menu-item"
                                                                      key={i}
                                                                  >
                                                                      <Link
                                                                          to={
                                                                              third_item.link
                                                                          }
                                                                      >
                                                                          {
                                                                              third_item.linkText
                                                                          }
                                                                      </Link>
                                                                  </li>
                                                              )
                                                          )}
                                                      </ul>
                                                  ) : null}
                                              </li>
                                          )
                                      )}
                                  </ul>
                              ) : null}
                          </li>
                      ))
                    : null}
                {/* Pages End */}
                {/* Social Media Start */}
                <li className="menu-section-title">Réseaux</li>
                <li className="menu-item">
                    <Link
                        to="https://www.facebook.com/js.market.maroc/"
                        target="_blank"
                    >
                        <i className="flaticon-facebook" />
                        Facebook
                    </Link>
                </li>
                <li className="menu-item">
                    <Link
                        to="https://www.instagram.com/js.market.maroc/"
                        target="_blank"
                    >
                        <i className="flaticon-instagram" /> Instagram
                    </Link>
                </li>
                <li className="menu-item">
                    <Link to={config.whatsappLink} target="_blank">
                        <i className="fab fa-whatsapp" /> Whatsapp
                    </Link>
                </li>

                {/* Social Media End */}
            </ul>
        </div>
    );
};

export default Mobilemenu;
