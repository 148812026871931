// NODE MODULES
import { useState } from "react";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Zoom from "react-medium-image-zoom";

// IMPORTS
import items from "data/items.json";
import imgs from "data/imgs";
import ImageSlider from "./ImageSlider";
import config from "data/config.json";

// CONTENT
const gallerytip = <Tooltip>Photos</Tooltip>;
const bedstip = <Tooltip>Chambre</Tooltip>;
const bathstip = <Tooltip>Salle de bain</Tooltip>;
const parkingtip = <Tooltip>Parking</Tooltip>;
const areatip = <Tooltip>Surface</Tooltip>;

const ItemsList = () => {
    const [show, setShow] = useState(false);
    const [modalImages, setModalImages] = useState<string[]>([]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div className="section">
            <div className="container">
                <div className="section-title-wrap section-header flex-header">
                    <div className="section-title-text">
                        <h5 className="custom-primary">Trouvez votre bien</h5>
                        <h2 className="title">Liste de biens du projet</h2>
                    </div>
                </div>
                <div className="row">
                    {/* Listing Start */}
                    {items.map((item) => (
                        <div key={item.id} className="col-lg-4">
                            <div className="listing">
                                <div className="listing-thumbnail">
                                    <div className="custum-image-container">
                                        <Zoom>
                                            <img
                                                src={
                                                    imgs.find(
                                                        (it) =>
                                                            it.id === item.id
                                                    )?.imgs[0]
                                                }
                                                alt="not-found"
                                                className="overflow-hidden"
                                            />
                                        </Zoom>
                                    </div>

                                    <div className="listing-badges">
                                        {item.star === true ? (
                                            <span className="listing-badge featured">
                                                <i className="fas fa-star" />
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                        {item.sale === true ? (
                                            <span className="listing-badge sale">
                                                A Vendre
                                            </span>
                                        ) : (
                                            <span className="listing-badge pending">
                                                Vendu
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="listing-body">
                                    <h5 className="listing-title">
                                        {item.title}
                                    </h5>
                                    <span className="listing-price">
                                        {item.etage} Étage
                                    </span>
                                    <p className="listing-text">{item.text}</p>
                                    <div className="acr-listing-icons">
                                        <OverlayTrigger overlay={bedstip}>
                                            <div className="acr-listing-icon">
                                                <i className="flaticon-bedroom" />
                                                <span className="acr-listing-icon-value">
                                                    {item.rooms}
                                                </span>
                                            </div>
                                        </OverlayTrigger>
                                        <OverlayTrigger overlay={bathstip}>
                                            <div className="acr-listing-icon">
                                                <i className="flaticon-bathroom" />
                                                <span className="acr-listing-icon-value">
                                                    {item.bathrooms}
                                                </span>
                                            </div>
                                        </OverlayTrigger>
                                        {item.parking && (
                                            <OverlayTrigger
                                                overlay={parkingtip}
                                            >
                                                <div className="acr-listing-icon">
                                                    <i className="flaticon-garage" />
                                                    <span className="acr-listing-icon-value">
                                                        1
                                                    </span>
                                                </div>
                                            </OverlayTrigger>
                                        )}

                                        <OverlayTrigger overlay={areatip}>
                                            <div className="acr-listing-icon">
                                                <i className="flaticon-ruler" />
                                                <span className="acr-listing-icon-value">
                                                    {new Intl.NumberFormat().format(
                                                        item.area
                                                    )}{" "}
                                                    m²
                                                </span>
                                            </div>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="listing-gallery-wrapper">
                                        <div>
                                            <Link
                                                to={`${config.whatsappLink}?text=${item.whatsappText}`}
                                                target="_blank"
                                                className="btn-custom secondary me-1"
                                            >
                                                <i className="fab fa-whatsapp" />
                                            </Link>
                                            <Link
                                                to={config.phoneLink}
                                                className="btn-custom secondary"
                                            >
                                                <i className="fas fa-phone" />
                                            </Link>
                                        </div>
                                        <OverlayTrigger overlay={gallerytip}>
                                            <Button
                                                onClick={() => {
                                                    setModalImages(
                                                        (prevModalImgs) => {
                                                            handleShow();
                                                            return (
                                                                imgs.find(
                                                                    (it) =>
                                                                        it.id ===
                                                                        item.id
                                                                )?.imgs ?? []
                                                            );
                                                        }
                                                    );
                                                }}
                                                className="listing-gallery btn-lg"
                                            >
                                                <i className="fas fa-camera" />
                                            </Button>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                <ImageSlider
                                    show={show}
                                    handleClose={handleClose}
                                    images={modalImages}
                                />
                            </div>
                        </div>
                    ))}
                    {/* Listing End */}
                </div>
            </div>
        </div>
    );
};

export default ItemsList;
