// NODE MODULES
import { Link } from "react-router-dom";

// IMPORTS
import config from "data/config.json";

// CONTENT
const ContactInfo = () => {
    const items = [
        {
            id: 1,
            icon: "telephone",
            title: "Applez nous",
            text: "Notre service est disponible par téléphone pour tous renseignement :",
            btnUrl: config.phoneLink,
            btnText: config.phoneText,
        },
        {
            id: 2,
            icon: "location",
            title: "Venez nous voir",
            text: "Nous somme disponible tous les jours de la semaine pour programmer une viste !",
            btnUrl: "https://maps.app.goo.gl/HkFe11NqooptQxdL6",
            btnText: "Google Maps",
        },
        {
            id: 3,
            icon: "speech-bubble",
            title: "Contactez nous par message",
            text: "Notre numéro dédié Whatsapp est la pour toute demande d'information tardive ou pendant les week-ends !",
            btnUrl: config.whatsappLink,
            btnText: "Whatsapp",
        },
    ];

    return (
        <div className="section section-padding infographics-3">
            <div className="container">
                <div className="row">
                    {items.map((item) => (
                        <div key={item.id} className="col-lg-4">
                            <div className="acr-infographic-item">
                                <i className={"flaticon-" + item.icon + ""} />
                                <div className="acr-infographic-item-body">
                                    <h5>{item.title}</h5>
                                    <p>{item.text}</p>
                                    <Link
                                        to={item.btnUrl}
                                        target="_blank"
                                        className="btn-custom secondary btn-sm"
                                    >
                                        {item.btnText}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ContactInfo;
