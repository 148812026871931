import b1img1 from "assets/img/ressources/biens/b1/1.jpg";
import b1img2 from "assets/img/ressources/biens/b1/2.jpg";
import b1img3 from "assets/img/ressources/biens/b1/3.jpg";
import b1img4 from "assets/img/ressources/biens/b1/4.jpg";
import b1img5 from "assets/img/ressources/biens/b1/5.jpg";
import b1img6 from "assets/img/ressources/biens/b1/6.jpg";

import b2img1 from "assets/img/ressources/biens/b2/1.jpg";
import b2img2 from "assets/img/ressources/biens/b2/2.jpg";
import b2img3 from "assets/img/ressources/biens/b2/3.jpg";
import b2img4 from "assets/img/ressources/biens/b2/4.jpg";
import b2img5 from "assets/img/ressources/biens/b2/5.jpg";
import b2img6 from "assets/img/ressources/biens/b2/6.jpg";

import b3img1 from "assets/img/ressources/biens/b3/1.jpg";
import b3img2 from "assets/img/ressources/biens/b3/2.jpg";
import b3img3 from "assets/img/ressources/biens/b3/3.jpg";
import b3img4 from "assets/img/ressources/biens/b3/4.jpg";
import b3img5 from "assets/img/ressources/biens/b3/5.jpg";
import b3img6 from "assets/img/ressources/biens/b3/6.jpg";

import b4img1 from "assets/img/ressources/biens/b4/1.jpg";
import b4img2 from "assets/img/ressources/biens/b4/2.jpg";
import b4img3 from "assets/img/ressources/biens/b4/3.jpg";
import b4img4 from "assets/img/ressources/biens/b4/4.jpg";
import b4img5 from "assets/img/ressources/biens/b4/5.jpg";
import b4img6 from "assets/img/ressources/biens/b4/6.jpg";

// N5
import n5img1 from "assets/img/ressources/biens/n5/1.jpg";
import n5img2 from "assets/img/ressources/biens/n5/2.jpg";
import n5img3 from "assets/img/ressources/biens/n5/3.jpg";
import n5img4 from "assets/img/ressources/biens/n5/4.jpg";
import n5img5 from "assets/img/ressources/biens/n5/5.jpg";

// N6 => VENDU

// N7
import n7img1 from "assets/img/ressources/biens/n7/1.jpg";
import n7img2 from "assets/img/ressources/biens/n7/2.jpg";
import n7img3 from "assets/img/ressources/biens/n7/3.jpg";
import n7img4 from "assets/img/ressources/biens/n7/4.jpg";
import n7img5 from "assets/img/ressources/biens/n7/5.jpg";

// N8
import n8img1 from "assets/img/ressources/biens/n8/1.jpg";
import n8img2 from "assets/img/ressources/biens/n8/2.jpg";
import n8img3 from "assets/img/ressources/biens/n8/3.jpg";
import n8img4 from "assets/img/ressources/biens/n8/4.jpg";
import n8img5 from "assets/img/ressources/biens/n8/5.jpg";
import n8img6 from "assets/img/ressources/biens/n8/6.jpg";
import n8img7 from "assets/img/ressources/biens/n8/7.jpg";
import n8img8 from "assets/img/ressources/biens/n8/8.jpg";
import n8img9 from "assets/img/ressources/biens/n8/9.jpg";
import n8img10 from "assets/img/ressources/biens/n8/10.jpg";

// N9
import n9img1 from "assets/img/ressources/biens/n9/1.jpg";
import n9img2 from "assets/img/ressources/biens/n9/2.jpg";
import n9img3 from "assets/img/ressources/biens/n9/3.jpg";
import n9img4 from "assets/img/ressources/biens/n9/4.jpg";
import n9img5 from "assets/img/ressources/biens/n9/5.jpg";
import n9img6 from "assets/img/ressources/biens/n9/6.jpg";
import n9img7 from "assets/img/ressources/biens/n9/7.jpg";

// N10
import n10img1 from "assets/img/ressources/biens/n10/1.jpg";
import n10img2 from "assets/img/ressources/biens/n10/2.jpg";
import n10img3 from "assets/img/ressources/biens/n10/3.jpg";
import n10img4 from "assets/img/ressources/biens/n10/4.jpg";
import n10img5 from "assets/img/ressources/biens/n10/5.jpg";
import n10img6 from "assets/img/ressources/biens/n10/6.jpg";
import n10img7 from "assets/img/ressources/biens/n10/7.jpg";
import n10img8 from "assets/img/ressources/biens/n10/8.jpg";
import n10img9 from "assets/img/ressources/biens/n10/9.jpg";
import n10img10 from "assets/img/ressources/biens/n10/10.jpg";

// N11 => VENDU

// N12
import n12img1 from "assets/img/ressources/biens/n12/1.jpg";
import n12img2 from "assets/img/ressources/biens/n12/2.jpg";
import n12img3 from "assets/img/ressources/biens/n12/3.jpg";
import n12img4 from "assets/img/ressources/biens/n12/4.jpg";
import n12img5 from "assets/img/ressources/biens/n12/5.jpg";
import n12img6 from "assets/img/ressources/biens/n12/6.jpg";
import n12img7 from "assets/img/ressources/biens/n12/7.jpg";
import n12img8 from "assets/img/ressources/biens/n12/8.jpg";
import n12img9 from "assets/img/ressources/biens/n12/9.jpg";
import n12img10 from "assets/img/ressources/biens/n12/10.jpg";
import n12img11 from "assets/img/ressources/biens/n12/11.jpg";
import n12img12 from "assets/img/ressources/biens/n12/12.jpg";

// N13 => VENDU

// N14 => PAS IMAGES

// N15
import n15img1 from "assets/img/ressources/biens/n15/1.jpg";
import n15img2 from "assets/img/ressources/biens/n15/2.jpg";
import n15img3 from "assets/img/ressources/biens/n15/3.jpg";
import n15img4 from "assets/img/ressources/biens/n15/4.jpg";
import n15img5 from "assets/img/ressources/biens/n15/5.jpg";
import n15img6 from "assets/img/ressources/biens/n15/6.jpg";
import n15img7 from "assets/img/ressources/biens/n15/7.jpg";

// N16 => VENDU

// N17
import n17img1 from "assets/img/ressources/biens/n17/1.jpg";
import n17img2 from "assets/img/ressources/biens/n17/2.jpg";
import n17img3 from "assets/img/ressources/biens/n17/3.jpg";
import n17img4 from "assets/img/ressources/biens/n17/4.jpg";
import n17img5 from "assets/img/ressources/biens/n17/5.jpg";

// N18
import n18img1 from "assets/img/ressources/biens/n18/1.jpg";
import n18img2 from "assets/img/ressources/biens/n18/2.jpg";
import n18img3 from "assets/img/ressources/biens/n18/3.jpg";
import n18img4 from "assets/img/ressources/biens/n18/4.jpg";
import n18img5 from "assets/img/ressources/biens/n18/5.jpg";
import n18img6 from "assets/img/ressources/biens/n18/6.jpg";
import n18img7 from "assets/img/ressources/biens/n18/7.jpg";
import n18img8 from "assets/img/ressources/biens/n18/8.jpg";
import n18img9 from "assets/img/ressources/biens/n18/9.jpg";
import n18img10 from "assets/img/ressources/biens/n18/10.jpg";

// N19
import n19img1 from "assets/img/ressources/biens/n19/1.jpg";
import n19img2 from "assets/img/ressources/biens/n19/2.jpg";
import n19img3 from "assets/img/ressources/biens/n19/3.jpg";
import n19img4 from "assets/img/ressources/biens/n19/4.jpg";
import n19img5 from "assets/img/ressources/biens/n19/5.jpg";
import n19img6 from "assets/img/ressources/biens/n19/6.jpg";

// N20 => VENDU

// N21 => PAS IMAGES

// N22
import n22img1 from "assets/img/ressources/biens/n22/1.jpg";
import n22img2 from "assets/img/ressources/biens/n22/2.jpg";
import n22img3 from "assets/img/ressources/biens/n22/3.jpg";
import n22img4 from "assets/img/ressources/biens/n22/4.jpg";
import n22img5 from "assets/img/ressources/biens/n22/5.jpg";
import n22img6 from "assets/img/ressources/biens/n22/6.jpg";
import n22img7 from "assets/img/ressources/biens/n22/7.jpg";
import n22img8 from "assets/img/ressources/biens/n22/8.jpg";
import n22img9 from "assets/img/ressources/biens/n22/9.jpg";
import n22img10 from "assets/img/ressources/biens/n22/10.jpg";
import n22img11 from "assets/img/ressources/biens/n22/11.jpg";

// N23
import n23img1 from "assets/img/ressources/biens/n23/1.jpg";
import n23img2 from "assets/img/ressources/biens/n23/2.jpg";
import n23img3 from "assets/img/ressources/biens/n23/3.jpg";
import n23img4 from "assets/img/ressources/biens/n23/4.jpg";
import n23img5 from "assets/img/ressources/biens/n23/5.jpg";
import n23img6 from "assets/img/ressources/biens/n23/6.jpg";
import n23img7 from "assets/img/ressources/biens/n23/7.jpg";
import n23img8 from "assets/img/ressources/biens/n23/8.jpg";
import n23img9 from "assets/img/ressources/biens/n23/9.jpg";
import n23img10 from "assets/img/ressources/biens/n23/10.jpg";
import n23img11 from "assets/img/ressources/biens/n23/11.jpg";
import n23img12 from "assets/img/ressources/biens/n23/12.jpg";

// N24
import n24img1 from "assets/img/ressources/biens/n24/1.jpg";
import n24img2 from "assets/img/ressources/biens/n24/2.jpg";
import n24img3 from "assets/img/ressources/biens/n24/3.jpg";
import n24img4 from "assets/img/ressources/biens/n24/4.jpg";
import n24img5 from "assets/img/ressources/biens/n24/5.jpg";
import n24img6 from "assets/img/ressources/biens/n24/6.jpg";
import n24img7 from "assets/img/ressources/biens/n24/7.jpg";
import n24img8 from "assets/img/ressources/biens/n24/8.jpg";
import n24img9 from "assets/img/ressources/biens/n24/9.jpg";
import n24img10 from "assets/img/ressources/biens/n24/10.jpg";

// N25 => VENDU

// eslint-disable-next-line import/no-anonymous-default-export
export default [
    // VENDU
    // N6
    {
        id: "6",
        imgs: [b1img1, b1img2, b1img3, b1img4, b1img5, b1img6],
    },
    // N11
    {
        id: "11",
        imgs: [b2img1, b2img2, b2img3, b2img4, b2img5, b2img6],
    },
    // N13
    {
        id: "13",
        imgs: [b3img1, b3img2, b3img3, b3img4, b3img5, b3img6],
    },
    // N16
    {
        id: "16",
        imgs: [b4img1, b4img2, b4img3, b4img4, b4img5, b4img6],
    },
    // N20
    {
        id: "20",
        imgs: [b1img1, b1img2, b1img3, b1img4, b1img5, b1img6],
    },
    // N25
    {
        id: "25",
        imgs: [b2img1, b2img2, b2img3, b2img4, b2img5, b2img6],
    },
    // N14
    {
        id: "14",
        imgs: [b3img1, b3img2, b3img3, b3img4, b3img5, b3img6],
    },
    // N21
    {
        id: "21",
        imgs: [b4img1, b4img2, b4img3, b4img4, b4img5, b4img6],
    },

    // CORRECT
    // N5
    {
        id: "5",
        imgs: [n5img1, n5img2, n5img3, n5img4, n5img5],
    },
    // N7
    {
        id: "7",
        imgs: [n7img1, n7img2, n7img3, n7img4, n7img5],
    },
    // N8
    {
        id: "8",
        imgs: [
            n8img1,
            n8img2,
            n8img3,
            n8img4,
            n8img5,
            n8img6,
            n8img7,
            n8img8,
            n8img9,
            n8img10,
        ],
    },
    // N9
    {
        id: "9",
        imgs: [n9img1, n9img2, n9img3, n9img4, n9img5, n9img6, n9img7],
    },
    // N10
    {
        id: "10",
        imgs: [
            n10img1,
            n10img2,
            n10img3,
            n10img4,
            n10img5,
            n10img6,
            n10img7,
            n10img8,
            n10img9,
            n10img10,
        ],
    },
    // N12
    {
        id: "12",
        imgs: [
            n12img1,
            n12img2,
            n12img3,
            n12img4,
            n12img5,
            n12img6,
            n12img7,
            n12img8,
            n12img9,
            n12img10,
            n12img11,
            n12img12,
        ],
    },
    // N15
    {
        id: "15",
        imgs: [n15img1, n15img2, n15img3, n15img4, n15img5, n15img6, n15img7],
    },
    // N17
    {
        id: "17",
        imgs: [n17img1, n17img2, n17img3, n17img4, n17img5],
    },
    // N18
    {
        id: "18",
        imgs: [
            n18img1,
            n18img2,
            n18img3,
            n18img4,
            n18img5,
            n18img6,
            n18img7,
            n18img8,
            n18img9,
            n18img10,
        ],
    },
    // N19
    {
        id: "19",
        imgs: [n19img1, n19img2, n19img3, n19img4, n19img5, n19img6],
    },
    // N22
    {
        id: "22",
        imgs: [
            n22img1,
            n22img2,
            n22img3,
            n22img4,
            n22img5,
            n22img6,
            n22img7,
            n22img8,
            n22img9,
            n22img10,
            n22img11,
        ],
    },
    // N23
    {
        id: "23",
        imgs: [
            n23img1,
            n23img2,
            n23img3,
            n23img4,
            n23img5,
            n23img6,
            n23img7,
            n23img8,
            n23img9,
            n23img10,
            n23img11,
            n23img12,
        ],
    },
    // N24
    {
        id: "24",
        imgs: [
            n24img1,
            n24img2,
            n24img3,
            n24img4,
            n24img5,
            n24img6,
            n24img7,
            n24img8,
            n24img9,
            n24img10,
        ],
    },
];
