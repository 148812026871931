// NODE MODULES
import React from "react";
import ReactDOM from "react-dom/client";

// IMPORTS
import App from "App";

// IMPORTS
import "../node_modules/animate.css/animate.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/slick-carousel/slick/slick.css";
import "../node_modules/slick-carousel/slick/slick-theme.css";
import "../node_modules/magnific-popup/dist/magnific-popup.css";
import "../node_modules/leaflet/dist/leaflet.css";
import "../node_modules/react-medium-image-zoom/dist/styles.css";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import "assets/fonts/flaticon/flaticon.css";
import "assets/fonts/font-awesome/css/all.min.css";
import "assets/css/style.css";
import "assets/css/user.css";

// CONTENT
const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
