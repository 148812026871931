// NODE MODULES
import {
    Navigate,
    RouterProvider,
    createBrowserRouter,
} from "react-router-dom";

// COMPONENTS
import Error from "components/pages/Error";
import Home from "components/pages/Home";
import Contact from "components/pages/Contact";

// CONTENT
const App = () => {
    const router = createBrowserRouter([
        {
            path: "*",
            element: <Navigate to={"/error-404"} replace />,
        },
        {
            path: "/",
            children: [
                {
                    index: true,
                    element: <Home />,
                },
                {
                    path: "/contact",
                    element: <Contact />,
                },
                {
                    path: "/error-404",
                    element: <Error />,
                },
            ],
        },
    ]);

    return <RouterProvider router={router} />;
};

export default App;
