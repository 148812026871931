// NODE MODULES
import { Fragment } from "react";
import DocumentMeta from "react-document-meta";

// COMPONENTS
import Breadcrumb from "components/layouts/Breadcrumb";
import Footer from "components/layouts/Footer";
import Header from "components/layouts/Header";
import Content from "components/sections/error/Content";

// IMPORTS
import config from "data/config.json";

// CONTENT
const Error = () => {
    const meta = {
        title: `${config.appName} - Agence de communication | Erreur 404`,
        description: `${config.appName} : Page d'erreur !`,
    };

    return (
        <Fragment>
            <DocumentMeta {...meta}>
                <Header />
                <Breadcrumb breadcrumb={{ pagename: "Erreur 404" }} />
                <Content />
                <Footer />
            </DocumentMeta>
        </Fragment>
    );
};

export default Error;
