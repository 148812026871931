// NODE MODULES
import { Link } from "react-router-dom";

// CONTENT
const Content = () => (
    <div className="section bg-norepeat bg-bottom error-custom">
        <div className="container">
            <div className="section-404">
                <div className="section-404-text mb-0">
                    <h1 className="title">Erreur 404</h1>
                    <p className="subtitle">
                        Il semble que la page que vous recherchez n'existe pas.
                    </p>
                    <Link to="/" className="btn-custom">
                        Retourner à l'accueil
                    </Link>
                </div>
            </div>
        </div>
        <div className="acr-clouds">
            <div className="cloud-one cloud-one-custom" />
            <div className="cloud-two cloud-one-custom" />
        </div>
    </div>
);

export default Content;
