// NODE MODULES
import { Fragment } from "react";
import DocumentMeta from "react-document-meta";

// COMPONENTS
import Breadcrumb from "components/layouts/Breadcrumb";
import Footer from "components/layouts/Footer";
import Header from "components/layouts/Header";
import Content from "components/sections/contact/Content";

// IMPORTS
import config from "data/config.json";

// CONTENT
const Contact = () => {
    const meta = {
        title: `${config.appName} - Agence de communication | Contactez nous`,
        description: `${config.appName} : Page de contact !`,
    };

    return (
        <Fragment>
            <DocumentMeta {...meta}>
                <Header />
                <Breadcrumb breadcrumb={{ pagename: "Contact" }} />
                <Content />
                <Footer />
            </DocumentMeta>
        </Fragment>
    );
};

export default Contact;
